import Axios from 'axios';
import { ThemeOptions, WithdrawalTypes } from 'interfaces/main';
import defaultPallete from 'muiTheme/config/defaultPallete';
import { EnableRedirectToUrl, LogoSettings, TradingPlatformInfoTypeEnum } from 'services/generatedClientFromSwagger';
import { Trading4ProLevelEnum } from 'store/analytics/interfaces';

(window as any).envObj = import.meta.env;

export interface PaymentTypesNew {
    active: boolean;
    name: string;
    order: number;
    overrideName?: string;
    type?: string;
    openNewTab?: boolean;
}

export interface DepositCrypto {
    label: string;
    link?: string;
    wallet?: string;
    currency?: string;
    text?: string;
}

export interface DepositLinks {
    label: string;
    link: string;
}

export enum FeatureFlag {
    PROP = 'prop',
}

export interface IBAN {
    currency: string;
    account_number: string;
    iban: string;
}

export type DepositWireTransfer = {
    bankName: string;
    bandAddress: string;
    beneficiary: string;
    beneficiaryAddress: string;
    sortCode: string;
    SWIFT: string;
    referenceAccountName: string;
    bankClearingCode: string;
    correspondentBank: string;
    IBAN_list: Array<IBAN>;
    additionalInfo: string;
};

interface ConfigInterface {
    targetPercentageDivideBy: number;
    primaryColor: string;
    secondaryColor: string;
    timeframeKey: string;
    refund_cancellation_policy_modal?: string;
    symbolKey: string;
    chartTypeKey: string;
    lastSelectedSymbolKey: string;
    version: string;
    siteType: string;
    namespace: string;
    tokenKey: string;
    lastSelectedTpKey: string;
    api: { URL: string };
    ipApi: { URL: string };
    googleClientId: string;
    googleApiKey: string;
    recaptchaKey: string;
    theme: {
        fixedDisclaimerRow: boolean;
        default_theme: ThemeOptions;
        local_storage_key: string;
    };
    featuresFlags: {
        [name: string | FeatureFlag]: boolean;
    };
    wtFeaturesFlags: {
        [name: string]: boolean;
    };
    registerFlags: {
        [name: string]: boolean;
    };
    preDepositFlags: {
        [name: string]: boolean;
    };
    paymentTypesFlags: {
        [name: string]: boolean;
    };
    paymentTypesFlagsNew: Array<PaymentTypesNew>;
    links: {
        warnings_and_disclosures: string;
        terms_and_conditions: string;
        privacy_policy: string;
        legal_information?: string;
        cookie_policy?: string;
        refund_cancellation_policy?: string;
    };
    linkRememberKey: string;
    tradingAnalysis: {
        t4pLevel: Trading4ProLevelEnum;
        tutorialLinks: {
            analystView: string;
            featuredIdeas: string;
            economicCalendar: string;
        };
    };
    payment: {
        depositRedirectUrl: string;
    };
    mixpanelKey: string;
    pallete: any;
    supportEmail: string;
    traderAgreementLink: string;
    siteName: string;
    COMPANY_NAME: string;
    REGULATION_NAME: string;
    CERTIFICATE_NUMBER: string;
    REGISTRATION_NUMBER: string;
    REGULATION_COUNTRY: string;
    downloadLinks: {
        tradingPlatform: string;
        mt4: string;
        mt5: string;
        ios: string;
        android: string;
        iosAppCode?: string;
        androidAppCode?: string;
    };
    kycType?: string;
    minDeposit: number;
    maxDeposit: number;
    minWithdrawal: number;
    cfd_percentage: Promise<string>;
    depositLinks: Array<DepositLinks>;
    depositOutsource: {
        url: string;
        blank: string;
    };
    tradersEducation: {
        url: string;
        showTitleArea: string;
    };
    depositCrypto: Array<DepositCrypto>;
    msgPopupKey: string;
    depositWireTransfer: Array<DepositWireTransfer>;
    disableRecaptcha: boolean;
    platformType?: 'sirix' | 'mt4';
    mt4platformLink?: string;
    platformT4PSignalKey: string;
    platformPageKey: string;
    platformTradeTypeKey: string;
    platformRenewUserKey: string;
    luckyOrange: {
        src?: string;
        siteId?: string;
    };
    redirectAfterLogin?: string;
    redirectAfterRegister?: string;
    initialTPType?: TradingPlatformInfoTypeEnum;
    whatsAppContactNumber?: string;
    enableReduxDevtools: boolean;
    FACEBOOK_APP_ID: string;
    outsourceWithdrawal?: string;
    smartico: {
        guid?: string;
        brandKey?: string;
    };
    withdrawalTypes: WithdrawalTypes[];
    cryptoCurrencies: string[];
    depositWireTransferInfo: { fee: number; min: number; max: number };
    supportedLanguages: string[];
    logRocketId?: string;
    enableSignals?: boolean;
    reactAppChatBaseKey?: string;
    intercomAppId: string;
    cryptoSubtitle?: string;
    traderEducationBearer: string;
    traderEducationDomain: string;
    propCouponCode: string;
    clientSupportSubjectSelectOptions: Array<string>;
    dynamicFileTypes: Array<string>;
    redirectToExternalEndpoint: EnableRedirectToUrl;
    splashScreenBgColor: string;
    logoRedirectUrl?: string;
    logoSettings?: LogoSettings;
    gtmContainerId?: string;
}

const fieldByEnv = {
    development: {
        pallete: (() => {
            try {
                return JSON.parse(import.meta.env.REACT_APP_PALLETE || '');
            } catch (error) {
                return defaultPallete;
            }
        })(),
    },
    production: {
        pallete: (() => {
            try {
                return JSON.parse(import.meta.env.REACT_APP_PALLETE || '');
            } catch (error) {
                return defaultPallete;
            }
        })(),
    },
};

const fieldsByEnviroment = () => {
    try {
        const currentEnv = import.meta.env.REACT_APP_ENV || 'development';
        switch (currentEnv) {
            case 'production':
            case 'prod':
                return fieldByEnv.production;
            default:
                return fieldByEnv.development;
        }
    } catch (error) {
        return fieldByEnv.development;
    }
};

const common: Omit<ConfigInterface, 'api'> = {
    targetPercentageDivideBy: 10000000,
    symbolKey: 'selectedSymbol',
    timeframeKey: 'selectedTimeframe',
    chartTypeKey: 'selectedChartType',
    lastSelectedSymbolKey: 'lastSelectedSymbol',
    primaryColor: import.meta.env.REACT_APP_PRIMARY_COLOR || '',
    secondaryColor: import.meta.env.REACT_APP_SECONDARY_COLOR || '',

    namespace: import.meta.env.REACT_APP_NAMESPACE || '',
    siteType: 'cz',
    supportEmail: import.meta.env.REACT_APP_SUPPORT_EMAIL || '',
    traderAgreementLink: import.meta.env.REACT_APP_TRADER_AGREEMENT_LINK || '',
    siteName: import.meta.env.REACT_APP_SITE_NAME || '',
    downloadLinks: {
        tradingPlatform: import.meta.env.REACT_APP_DOWNLOAD_SIRIX || '',
        mt4: import.meta.env.REACT_APP_DOWNLOAD_MT4 || '',
        mt5: import.meta.env.REACT_APP_DOWNLOAD_MT5 || '',
        ios: import.meta.env.REACT_APP_DOWNLOAD_IOS || '',
        android: import.meta.env.REACT_APP_DOWNLOAD_ANDROID || '',
        iosAppCode: import.meta.env.REACT_APP_DOWNLOAD_IOS_APP_CODE,
        androidAppCode: import.meta.env.REACT_APP_DOWNLOAD_ANDROID_APP_CODE,
    },
    version: import.meta.env.REACT_APP_VERSION || '',
    ipApi: {
        URL: import.meta.env.REACT_APP_IP_API_URL || 'http://ip-api.com/json?fields=8437763',
    },
    tokenKey: import.meta.env.REACT_APP_TOKEN_KEY_NAME || 'tokenKey',
    lastSelectedTpKey: 'lastSelectedTp',
    googleApiKey: import.meta.env.REACT_APP_GOOGLE_API_KEY || '',
    googleClientId: import.meta.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    theme: {
        fixedDisclaimerRow:
            import.meta.env.REACT_APP_FIXED_DISCLAIMER_ROW &&
            JSON.parse(import.meta.env.REACT_APP_FIXED_DISCLAIMER_ROW),
        default_theme: (import.meta.env.REACT_APP_DEFAULT_THEME as ThemeOptions | undefined) || ThemeOptions.LIGHT,
        local_storage_key: 'theme',
    },
    featuresFlags:
        (import.meta.env.REACT_APP_FEATURES_FLAGS && JSON.parse(import.meta.env.REACT_APP_FEATURES_FLAGS)) || {},
    wtFeaturesFlags:
        (import.meta.env.REACT_APP_WT_FEATURES_FLAGS && JSON.parse(import.meta.env.REACT_APP_WT_FEATURES_FLAGS)) || {},
    registerFlags:
        (import.meta.env.REACT_APP_REGISTER_FLAGS && JSON.parse(import.meta.env.REACT_APP_REGISTER_FLAGS)) || {},
    preDepositFlags:
        (import.meta.env.REACT_APP_PRE_DEPOSIT_FLAGS && JSON.parse(import.meta.env.REACT_APP_PRE_DEPOSIT_FLAGS)) || {},
    paymentTypesFlags:
        (import.meta.env.REACT_APP_PRE_PAYMENT_TYPES_FLAGS &&
            JSON.parse(import.meta.env.REACT_APP_PRE_PAYMENT_TYPES_FLAGS)) ||
        {},
    paymentTypesFlagsNew:
        (import.meta.env.REACT_APP_PRE_PAYMENT_TYPES_FLAGS_NEW &&
            JSON.parse(import.meta.env.REACT_APP_PRE_PAYMENT_TYPES_FLAGS_NEW)) ||
        [],
    recaptchaKey: import.meta.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '',
    refund_cancellation_policy_modal: import.meta.env.REACT_APP_REFUND_POLICY_FUNDED_MODAL || '',
    links: {
        warnings_and_disclosures:
            import.meta.env.REACT_APP_RISK_WARNING ||
            `${window.location.origin}/files/Risk-Warnings-and-Disclosures.pdf`,
        terms_and_conditions:
            import.meta.env.REACT_APP_TERMS_AND_CONDITION ||
            `${window.location.origin}/files/Terms-and-Conditions-of-Business.pdf`,
        privacy_policy:
            import.meta.env.REACT_APP_PRIVACY_POLICY || `${window.location.origin}/files/Privacy-and-Cookie-Policy.pdf`,
        legal_information: import.meta.env.REACT_APP_LEGAL_INFORMATION_LINK,
        cookie_policy: import.meta.env.REACT_APP_COOKIE_POLICY || `${window.location.origin}/files/Cookies_Policy.pdf`,
        refund_cancellation_policy:
            import.meta.env.REACT_APP_REFUND_CANCELLATION_POLICY ||
            `${window.location.origin}/files/Refund-and-Cancellation-Policy.pdf`,
    },
    linkRememberKey: 'redirectToAfterLogin',
    tradingAnalysis: {
        t4pLevel: (import.meta.env.REACT_APP_TRADING_4_PRO_LEVEL || Trading4ProLevelEnum.off) as Trading4ProLevelEnum,
        tutorialLinks: {
            analystView: import.meta.env.REACT_APP_TRADING_ANALYSIS_TUTORIAL_AV || '',
            featuredIdeas: import.meta.env.REACT_APP_TRADING_ANALYSIS_TUTORIAL_FI || '',
            economicCalendar: import.meta.env.REACT_APP_TRADING_ANALYSIS_TUTORIAL_EC || '',
        },
    },
    payment: {
        depositRedirectUrl: `${window.location.origin}/paymentRedirectUrl.html`,
    },
    mixpanelKey: import.meta.env.REACT_APP_MIX_PANEL_KEY || '',
    kycType: import.meta.env.REACT_APP_KYC_TYPE,
    minDeposit:
        import.meta.env.REACT_APP_MIN_DEPOSIT && !isNaN(Number(import.meta.env.REACT_APP_MIN_DEPOSIT))
            ? Number(import.meta.env.REACT_APP_MIN_DEPOSIT)
            : 0,
    maxDeposit:
        import.meta.env.REACT_APP_MAX_DEPOSIT && !isNaN(Number(import.meta.env.REACT_APP_MAX_DEPOSIT))
            ? Number(import.meta.env.REACT_APP_MAX_DEPOSIT)
            : 0,
    minWithdrawal:
        import.meta.env.REACT_APP_MIN_WITHDRAWAL && !isNaN(Number(import.meta.env.REACT_APP_MIN_WITHDRAWAL))
            ? Number(import.meta.env.REACT_APP_MIN_WITHDRAWAL)
            : 0,
    cfd_percentage: (async () => {
        try {
            const featureFlags =
                (import.meta.env.REACT_APP_FEATURES_FLAGS && JSON.parse(import.meta.env.REACT_APP_FEATURES_FLAGS)) ||
                {};
            if (!featureFlags['disclaimer'] && !featureFlags['disclaimer-eu']) return '';
            if (import.meta.env.REACT_APP_CFD_PERCENTAGE) return `${import.meta.env.REACT_APP_CFD_PERCENTAGE}%`;
            const res = await Axios.get('https://tools.leverate.com/data/data.json'); // todo: ask amit
            return res.data.cfd_percentage as string;
        } catch (error) {
            return '76.3%';
        }
    })(),
    depositLinks:
        (import.meta.env.REACT_APP_DEPOSIT_LINKS && JSON.parse(import.meta.env.REACT_APP_DEPOSIT_LINKS)) || [],
    depositOutsource:
        (import.meta.env.REACT_APP_DEPOSIT_OUTSOURCE && JSON.parse(import.meta.env.REACT_APP_DEPOSIT_OUTSOURCE)) || {},
    tradersEducation:
        (import.meta.env.REACT_APP_TRADERS_EDUCATION && JSON.parse(import.meta.env.REACT_APP_TRADERS_EDUCATION)) || {},
    depositCrypto:
        (import.meta.env.REACT_APP_DEPOSIT_CRYPTO && JSON.parse(import.meta.env.REACT_APP_DEPOSIT_CRYPTO)) || [],
    msgPopupKey: 'msgPopupSeen',
    depositWireTransfer:
        (import.meta.env.REACT_APP_DEPOSIT_WIRE_TRANSFER &&
            JSON.parse(import.meta.env.REACT_APP_DEPOSIT_WIRE_TRANSFER)) ||
        [],
    depositWireTransferInfo:
        (import.meta.env.REACT_APP_DEPOSIT_WIRE_TRANSFER_INFO &&
            JSON.parse(import.meta.env.REACT_APP_DEPOSIT_WIRE_TRANSFER_INFO)) ||
        {},
    disableRecaptcha:
        import.meta.env.REACT_APP_DISABLE_RECAPTCHA && JSON.parse(import.meta.env.REACT_APP_DISABLE_RECAPTCHA),
    platformType: import.meta.env.REACT_APP_PLATFORM_TYPE as any,
    mt4platformLink: import.meta.env.REACT_APP_MT4_PLATFORM_LINK,
    logoRedirectUrl: import.meta.env.REACT_APP_ENABLE_REDIRECT_TO_EXTERNAL_URL_LOGO,
    platformT4PSignalKey: 't4pSignal',
    platformPageKey: 'page',
    platformTradeTypeKey: 'type',
    platformRenewUserKey: 'platformRenewUser',
    luckyOrange: {
        src: import.meta.env.REACT_APP_LUCKY_ORANGE_SRC,
        siteId: import.meta.env.REACT_APP_LUCKY_ORANGE_SITEID,
    },
    redirectAfterLogin: import.meta.env.REACT_APP_REDIRECT_AFTER_LOGIN,
    redirectAfterRegister: import.meta.env.REACT_APP_REDIRECT_AFTER_REGISTER,
    initialTPType: import.meta.env.REACT_APP_INITIAL_TP_TYPE as TradingPlatformInfoTypeEnum | undefined,
    whatsAppContactNumber: import.meta.env.REACT_APP_WHATSAPP_NUMBER || '',
    enableReduxDevtools: Boolean(import.meta.env.REACT_APP_ENABLE_REDUX),
    COMPANY_NAME: import.meta.env.REACT_APP_COMPANY_NAME || '',
    REGULATION_NAME: import.meta.env.REACT_APP_REGULATION_NAME || '',
    CERTIFICATE_NUMBER: import.meta.env.REACT_APP_CERTIFICATE_NUMBER || '',
    REGISTRATION_NUMBER: import.meta.env.REACT_APP_REGISTERATION_NUMBER || '',
    REGULATION_COUNTRY: import.meta.env.REACT_APP_REGULATION_COUNTRY || '',
    FACEBOOK_APP_ID: import.meta.env.REACT_APP_FACEBOOK_APP_ID || '',
    outsourceWithdrawal: import.meta.env.REACT_APP_OUTSOURCE_WITHDRAWAL,
    smartico: {
        guid: import.meta.env.REACT_APP_SMARTICO_GUID || '',
        brandKey: import.meta.env.REACT_APP_SMARTICO_BRAND_KEY || '',
    },
    withdrawalTypes: (import.meta.env.REACT_APP_WITHDRAWAL_TYPES &&
        JSON.parse(import.meta.env.REACT_APP_WITHDRAWAL_TYPES)) || [WithdrawalTypes.CreditCard],
    supportedLanguages:
        import.meta.env.REACT_APP_I18N_SUPPORTED_LNG && JSON.parse(import.meta.env.REACT_APP_I18N_SUPPORTED_LNG),
    logRocketId: import.meta.env.REACT_APP_LOGROCKET_ID || '',
    enableSignals: import.meta.env.REACT_APP_SIGNALS && JSON.parse(import.meta.env.REACT_APP_SIGNALS),
    cryptoSubtitle: import.meta.env.REACT_APP_CRYPTO_SUBTITLE || '',
    cryptoCurrencies: (import.meta.env.REACT_APP_WITHDRAWAL_CRYPTO_CURRENCIES &&
        JSON.parse(import.meta.env.REACT_APP_WITHDRAWAL_CRYPTO_CURRENCIES).length &&
        JSON.parse(import.meta.env.REACT_APP_WITHDRAWAL_CRYPTO_CURRENCIES)) || ['BTC'],
    ...fieldsByEnviroment(),
    reactAppChatBaseKey: import.meta.env.REACT_APP_CHAT_BASE_KEY || '',
    intercomAppId: import.meta.env.REACT_APP_INTERCOM_APP_ID || '',
    traderEducationBearer: import.meta.env.REACT_APP_TRADER_EDUCATION_BEARER || '',
    traderEducationDomain: import.meta.env.REACT_APP_TRADER_EDUCATION_XDOMAIN || '',
    propCouponCode: import.meta.env.REACT_APP_PROP_COUPON_CODE || '',
    clientSupportSubjectSelectOptions:
        import.meta.env?.REACT_APP_CLIENT_SUPPORT_SUBJECT_SELECT_OPTIONS &&
        JSON.parse(import.meta.env.REACT_APP_CLIENT_SUPPORT_SUBJECT_SELECT_OPTIONS ?? '[]'),
    dynamicFileTypes:
        import.meta.env?.REACT_APP_UPLOAD_FILES_TYPES &&
        JSON.parse(import.meta.env.REACT_APP_UPLOAD_FILES_TYPES ?? '[]'),
    redirectToExternalEndpoint:
        import.meta.env?.REACT_APP_ENABLE_REDIRECT_TO_EXTERNAL_URL &&
        JSON.parse(import.meta.env?.REACT_APP_ENABLE_REDIRECT_TO_EXTERNAL_URL || '{}'),
    splashScreenBgColor: import.meta.env.REACT_APP_SPLASH_SCREEN_BG_COLOR || defaultPallete.light.primary.main,
    logoSettings: import.meta.env?.REACT_APP_LOGO_SETTINGS,
    gtmContainerId: import.meta.env.REACT_APP_GTM_CONTAINER_ID,
};

const config: ConfigInterface = {
    ...common,
    api: {
        URL: import.meta.env.REACT_APP_BACKEND_URL || `${window.location.protocol}//${window.location.host}`,
    },
};

export default config;
