import { FormControl, FormControlLabel, Checkbox as CheckboxMat, FormHelperText } from '@material-ui/core';
import { Icons } from 'components/Icon';
import React from 'react';
import { CheckboxProps } from './checkbox.scheme';
import useStyles from './checkbox.styles';
import classnames from 'classnames';

const Checkbox: React.FunctionComponent<CheckboxProps> = (props) => {
    const {
        defaultChecked,
        label,
        error = false,
        id,
        color,
        labelPlacement,
        helperText,
        checked,
        inheritColor,
        disableSidesMargin,
        disabled = false,
        inputRef,
        onChange,
        alignCenter,
        customLabel,
        className,
        fullWidth,
    } = props;
    const classes = useStyles({ error, inheritColor, disableSidesMargin, alignCenter, fullWidth });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        onChange && onChange(checked);
    };

    return (
        <FormControl error={error}>
            <FormControlLabel
                disabled={disabled}
                control={
                    <CheckboxMat
                        disabled={disabled}
                        size="medium"
                        color={color}
                        defaultChecked={defaultChecked}
                        checked={checked}
                        onChange={handleOnChange}
                        inputRef={inputRef}
                        id={id}
                        classes={{ root: classnames(classes.checkboxRoot, className) }}
                        icon={Icons.checkbox_piqk({})}
                        checkedIcon={Icons.checked_icon_piqk({})}
                    />
                }
                labelPlacement={labelPlacement}
                label={customLabel ? customLabel : label}
                classes={{ label: classes.label, root: classes.formControlRoot }}
            />
            {helperText && (
                <FormHelperText classes={{ error: classes.formHelperTextError }}>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
};

export default Checkbox;
