import { isTablet } from 'helpers/device_helper';
import { Pages } from 'interfaces/main';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { user_displayLayout } from 'store/user/selectors';
const pagesNames = new Set<string>([`/prop/${Pages.PROP__CHECKOUT}`]);
export const useDisplayLayout = () => {
    const userDisplayLayout = useSelector(user_displayLayout);
    const { pathname } = useLocation();

    return useMemo(() => {
        const matchPath = pathname ? pagesNames.has(pathname as Pages) : false;
        return !(!userDisplayLayout && matchPath && !isTablet());
    }, [userDisplayLayout, pathname]);
};
