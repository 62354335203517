import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Pages } from 'interfaces/main';
import DepositGuard from 'scenes/MyWallet/DepositGuard';
import WithdrawGuard from 'scenes/MyWallet/WithdrawGuard';
import PreCondition from 'scenes/MyWallet/PreCondition';
import PropGuard from './prop.guard';

export interface PropRouteProps {
    relativePath?: string;
    path?: string;
    exact?: boolean;
    component: any;
    depositGuard?: boolean;
    withdrawGuard?: boolean;
    hasFilteredChallengesGuard?: boolean;
    hasChallengesGuard?: boolean;
}

const routes: Array<PropRouteProps> = [
    {
        component: React.lazy(() => import('features/prop/scenes/NewChallenge')),
        relativePath: `/${Pages.PROP__NEW_CHALLENGES}`,
        exact: true,
    },
    {
        component: React.lazy(() => import('features/prop/scenes/NewChallengeDeposit')),
        relativePath: `/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT}`,
        depositGuard: true,
        exact: false,
    },
    {
        component: () => (
            <PreCondition
                customRedirectUrl={`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT}`}
            />
        ),
        relativePath: `/${Pages.PROP__NEW_CHALLENGES__PRE_CONDITION}`,
        exact: true,
    },
    {
        component: React.lazy(
            () => import('features/prop/scenes/NewChallengeDepositSuccess/NewChallengeDepositSuccess.container')
        ),
        relativePath: `/${Pages.PROP__NEW_CHALLENGES}/${Pages.PROP__NEW_CHALLENGES__DEPOSIT_SUCCESS}`,
        exact: false,
    },
    {
        component: React.lazy(() => import('features/prop/scenes/MyChallenges')),
        relativePath: `/${Pages.PROP__MY_CHALLENGES}`,
        exact: false,
    },
    {
        component: React.lazy(() => import('features/prop/scenes/MyPurchases')),
        relativePath: `/${Pages.PROP__MY_PURCHASES}`,
        exact: true,
        hasChallengesGuard: true,
    },
    {
        component: React.lazy(() => import('features/prop/scenes/Payout')),
        relativePath: `/${Pages.PROP__PAYOUT}`,
        exact: true,
    },
    {
        component: React.lazy(() => import('features/prop/scenes/PayoutWithdraw')),
        relativePath: `/${Pages.PROP__PAYOUT}/${Pages.PROP__PAYOUT__WITHDRAW}`,
        exact: true,
        withdrawGuard: true,
        hasChallengesGuard: true,
    },
    {
        component: React.lazy(() => import('features/prop/scenes/Checkout')),
        relativePath: `/${Pages.PROP__CHECKOUT}`,
        exact: true,
    },

    {
        component: () => <Redirect to={`/${Pages.PROP}/${Pages.PROP__MY_CHALLENGES}`} />,
    },
];

const PropRouter = (props) => {
    return (
        <Switch>
            {routes.map((route, index) => {
                route.path = props.match.path + (route.relativePath || '');
                let tempRoute = Object.assign({}, route);

                if (route.withdrawGuard) {
                    return <WithdrawGuard key={`Route-MyWallet-Deposit-Guard-${index}`} {...tempRoute} />;
                }

                if (route.hasFilteredChallengesGuard || route.hasChallengesGuard) {
                    return <PropGuard key={`Route-Prop-Guard-${index}`} {...tempRoute} />;
                }

                if (route.depositGuard) {
                    return (
                        <DepositGuard
                            key={`Route-MyWallet-Deposit-Guard-${index}`}
                            {...tempRoute}
                            customPreDepositUrl={`/${Pages.PROP}/${Pages.PROP__NEW_CHALLENGES__PRE_CONDITION}`}
                        />
                    );
                }

                return <Route key={`Route-Prop-${index}`} {...tempRoute} />;
            })}
        </Switch>
    );
};

export default withRouter(PropRouter);
