import React, { FunctionComponent, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { connect } from 'react-redux';
import { RootState } from 'store/index';
import { user_userInfo } from 'store/user/selectors';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger';

type IntercomChatProps = {
    userInfo: AccountInfoParsed;
};

const IntercomChat: FunctionComponent<IntercomChatProps> = ({ userInfo }) => {
    const { boot, shutdown, update, isOpen, show, trackEvent } = useIntercom();

    useEffect(() => {
        const adjustIntercomForMobile = () => {
            const intercomStyles = document.createElement('style');
            intercomStyles.innerHTML = `
                @media screen and (max-width: 768px) {
                    .intercom-lightweight-app-launcher{
                        bottom: 72px !important;
                        right: 15px !important;
                    }
                    .intercom-namespace div {
                        bottom: 72px !important;
                        right: 15px !important;
                    }
                }
            `;
            document.head.appendChild(intercomStyles);

            return () => {
                document.head.removeChild(intercomStyles);
            };
        };
        boot({
            email: userInfo.email ?? '',
            userId: userInfo.id,
        });

        adjustIntercomForMobile();

        trackEvent('onShow', {
            email: userInfo.email ?? '',
            userId: userInfo.id,
        });
        return () => {
            shutdown();
        };
    }, [boot, shutdown, trackEvent, userInfo.email, userInfo.id]);

    useEffect(() => {
        if (isOpen && typeof update === 'function') {
            update({
                email: userInfo?.email ?? '',
                userId: userInfo?.id,
            });
        }
    }, [isOpen, show, update, userInfo.email, userInfo.id]);

    return <></>;
};

const mapStateToProps = (state: RootState) => ({
    userInfo: user_userInfo(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(IntercomChat));
