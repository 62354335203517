import { GoogleLocationAutocompletePayload } from 'components/Inputs/GoogleLocationAutocomplete';
import { createSelector } from 'reselect';
import { RootState } from '../index';
import moment from 'moment';
import {
    AccountInfoParsedIsUsResidentEnum,
    TradingPlatformInfoTypeEnum,
} from 'services/generatedClientFromSwagger/models';
import config from 'config/common';
import { getTokenFromLocalStorage } from '../../helpers/token';
import { jwtDecode as decode } from 'jwt-decode';
import { kyc_status } from 'features/kyc/store/selector';
import { Pages } from 'interfaces/main';
import { IconsType } from 'components/Icon';
import { UserDepositStatus } from 'components/SideMenu/components/MenuTopSection';
import { uploadD_status } from 'features/upload-documents/store/selector';
import { sumsub_status } from 'features/sumsub/store/selector';
import { getTradingAnalysisStatus } from 'scenes/TradingAnalysis/trading-analysis.guard';
import { links } from 'scenes/TradingAnalysis/scenes/Home/trading-analysis-home.scheme';
import { TokenPayload } from 'helpers/jwtParser';

const rootReducerSelector = (state: RootState) => state;
export const userReducerSelector = (state: RootState) => state.user;
const paymentReducerSelector = (state: RootState) => state.payment;

export const user_promoCode = createSelector(userReducerSelector, (user) => user.userInfo.custom_field_promotioncode);

export const user_selectedTpAccount = createSelector(userReducerSelector, (user) => user.selectedTPAccount);

export const user_loader = createSelector(userReducerSelector, (user) => user.loader);

export const user_userInfo = createSelector(userReducerSelector, (user) => user.userInfo || {});

export const user_accountType = createSelector(
    userReducerSelector,
    (user) => user.userInfo?.typeOfAccount || undefined
);

export const user_loggedIn = createSelector(userReducerSelector, (user) => !!user.userInfo?.email);

export const user_accountFeatures = createSelector(userReducerSelector, (user) => user.userInfo?.accountFeatures);

export const user_isReachedCreditVolume = createSelector(userReducerSelector, (user) => user.isReachedCreditVolume);

export const user_verifyEmailSent = createSelector(userReducerSelector, (user) => user.verifyEmailSent);

export const user_isReachedCreditVolumeLoader = createSelector(
    userReducerSelector,
    (user) => user.isReachedCreditVolumeLoader
);

export const user_verified = createSelector(userReducerSelector, (user) => {
    if (!Boolean(config.featuresFlags['czFeatures'])) return true;
    const token = getTokenFromLocalStorage();
    if (!token) return undefined;
    const decodeJwt = decode<TokenPayload>(token);
    return decodeJwt?.isVerified;
});

// TODO:: cz_extension_phoneVerifyStatus return number instead enum string
export const user_phoneVerified = createSelector(userReducerSelector, (user) => {
    if (!Boolean(config.featuresFlags['czFeatures'])) return true;
    const token = getTokenFromLocalStorage();
    if (!token) return undefined;
    const decodeJwt = decode<TokenPayload>(token);
    return decodeJwt?.phoneVerified;
});

export const user_emailVerified = createSelector(userReducerSelector, (user) => {
    if (!Boolean(config.featuresFlags['czFeatures'])) return true;
    const token = getTokenFromLocalStorage();
    if (!token) return undefined;
    const decodeJwt = decode<TokenPayload>(token);
    return decodeJwt?.emailVerified;
});

export const user_fullName = createSelector(userReducerSelector, (user) => {
    const { firstName = '', lastName = '' } = user.userInfo;
    return `${firstName} ${lastName}`.trim();
});

export const user_verifyPhoneLoading = createSelector(userReducerSelector, (user) => user.verifyPhoneLoading);
export const user_sendPhoneVerificationLoading = createSelector(
    userReducerSelector,
    (user) => user.sendPhoneVerificationLoading
);
export const user_phoneVerificationSent = createSelector(userReducerSelector, (user) => user.phoneVerificationSent);

export const user_verifyEmailLoading = createSelector(userReducerSelector, (user) => user.verifyEmailLoading);

export const user_dateOfBirth = createSelector(userReducerSelector, (user) => {
    const { custom_field_dateOfBirth } = user.userInfo;
    if (
        custom_field_dateOfBirth &&
        moment(custom_field_dateOfBirth).isValid() &&
        moment(custom_field_dateOfBirth).isAfter('1901', 'years')
    ) {
        return moment(custom_field_dateOfBirth);
    }
    return null;
});

export const user_country = createSelector(userReducerSelector, (user) => {
    const { country } = user.userInfo;
    return country;
});

export const user_fullAddress = createSelector(userReducerSelector, (user) => {
    const { country, city, address1 } = user.userInfo;
    if (!country || !city || !address1) return '';
    return `${address1}, ${city}, ${country}`.trim();
});

export const user_locationAddressObject = createSelector(userReducerSelector, (user) => {
    const { country, state, city, address1, zipCode } = user.userInfo;
    return {
        country,
        state,
        city,
        address: address1,
        postalCode: zipCode,
    } as GoogleLocationAutocompletePayload;
});

export const user_firstName = createSelector(userReducerSelector, (user) => {
    if (!user.userInfo) return undefined;
    const { firstName } = user.userInfo;
    if (!firstName) return undefined;
    return firstName;
});

export const user_lastName = createSelector(userReducerSelector, (user) => {
    if (!user.userInfo) return undefined;
    const { firstName } = user.userInfo;
    if (!firstName) return undefined;
    return firstName;
});

export const user_phoneNumber = createSelector(userReducerSelector, (user) => {
    if (!user.userInfo) return undefined;
    const { phoneNumber } = user.userInfo;
    if (!phoneNumber || phoneNumber.length < 2) return undefined;
    return phoneNumber;
});

export const user_realTPAccounts = createSelector(userReducerSelector, (user) => {
    return (
        user.userInfo.tradingPlatformAccounts?.filter(
            (account) => account.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Real
        ) || []
    );
});

export const user_demoTPAccounts = createSelector(userReducerSelector, (user) => {
    return (
        user.userInfo.tradingPlatformAccounts?.filter(
            (account) => account.tradingPlatform?.type === TradingPlatformInfoTypeEnum.Demo
        ) || []
    );
});

export const user_minDepsit = createSelector(userReducerSelector, (user) => {
    if (config.featuresFlags['manualMinMaxDeposit']) {
        return config.minDeposit;
    }

    if (Boolean(config.featuresFlags['czFeatures'])) {
        return Number(user.userInfo.accountFeatures?.minDeposit?.amountTreshold) || 0;
    }

    return Number(user.userInfo.custom_field_minDeposit) || 0;
});

export const user_enabledReachedVolumeFeature = createSelector(userReducerSelector, (user) => {
    return (
        config.featuresFlags['czFeatures'] &&
        Boolean(user.userInfo.accountFeatures?.denyWithdrawalVolume?.amountTreshold)
    );
});

export const user_disableWithdrawalOpenPositions = createSelector(userReducerSelector, (user) => {
    if (Boolean(config.featuresFlags['disabledWithdrawalOpenPositions'])) return true;
    return (
        Boolean(config.featuresFlags['czFeatures']) &&
        Boolean(user.userInfo.accountFeatures?.denyWithdrawalOpenPositions?.enabled)
    );
});

export const user_maxDepsit = createSelector(userReducerSelector, (user) => {
    if (config.featuresFlags['manualMinMaxDeposit']) {
        return config.maxDeposit;
    }

    if (Boolean(config.featuresFlags['czFeatures'])) {
        const maxDeposit = user.userInfo.accountFeatures?.maxDeposit;
        return maxDeposit ? Number(maxDeposit?.amountTreshold) : null;
    }

    return Number(user.userInfo.custom_field_maxDeposit) || 0;
});

export const user_qualifiedToDeposit = createSelector(userReducerSelector, paymentReducerSelector, (user, payment) => {
    if (config.featuresFlags['manualMinMaxDeposit'] && Boolean(payment.depositType)) return true;

    if (Boolean(config.featuresFlags['czFeatures'])) {
        const maxDeposit = user.userInfo.accountFeatures?.maxDeposit;
        return Boolean(payment.depositType) && (!maxDeposit || maxDeposit?.amountTreshold > 0);
    }

    return (
        Boolean(payment.depositType) &&
        (Number(user.userInfo.custom_field_minDeposit) || 0) > 0 &&
        (Number(user.userInfo.custom_field_maxDeposit) || 0) > 0
    );
});

export const user_notQualifiedToUsResidency = createSelector(userReducerSelector, rootReducerSelector, (user, root) => {
    const { isUsResident } = root.user.userInfo;
    const { notUsResident: notUsResidentFlag } = config.preDepositFlags;
    return notUsResidentFlag && isUsResident && isUsResident === AccountInfoParsedIsUsResidentEnum.Yes;
});

export const user_refreshingUser = createSelector(userReducerSelector, (user) => user.refreshingUser);

export const user_donePreDepositCondition = createSelector(rootReducerSelector, (root) => {
    const {
        country,
        city,
        address1,
        custom_field_dateOfBirth,
        firstName: userFirstName,
        lastName: userLastName,
    } = root.user.userInfo;
    const {
        dateOfBirth: dateOfBirthFlag,
        address: addressFlag,
        phoneNumber: phoneNumberFlag,
        firstName,
        lastName,
    } = config.preDepositFlags;

    switch (true) {
        case dateOfBirthFlag &&
            (!custom_field_dateOfBirth || custom_field_dateOfBirth.split('T').includes('1900-01-01')):
        case addressFlag && (!country || !city || !address1):
        case phoneNumberFlag && !user_phoneNumber(root):
        case firstName && !userFirstName:
        case lastName && !userLastName:
        case (root?.prop_myPlans?.plans?.length ?? 0) < 1:
            return false;
        default:
            return true;
    }
});

export const user_displayLayout = createSelector(rootReducerSelector, (store) => {
    return !!store.prop_myPlans?.plans?.length && user_donePreDepositCondition(store);
});

export const user_msgPopupVisibleLogic = createSelector(userReducerSelector, () => {
    return true;
});

export const user_tcPermission = createSelector(userReducerSelector, (user) => {
    const { tcPermissions } = user.userInfo;
    return Boolean(tcPermissions);
});

export const user_denyWithdrawal = createSelector(userReducerSelector, (user) => {
    if (Boolean(config.featuresFlags['denyWithdrawal'])) return true;
    return Boolean(config.featuresFlags['czFeatures']) && user.userInfo.accountFeatures?.denyWithdrawal?.enabled;
});

export const user_tradingAnalysisActive = createSelector(userReducerSelector, (user) => {
    if (!Boolean(config.featuresFlags['czFeatures'])) {
        return false;
    }

    return links.filter((link, index) => {
        const status = getTradingAnalysisStatus(user.userInfo, link.guardFeature);
        links[index].status = status;
        return status;
    }).length;
});

export const pushNotificationPermissionStatusSelector = createSelector(userReducerSelector, (user) => {
    const { permissionStatus } = user;
    return permissionStatus;
});

export const userFlowGuard = createSelector(rootReducerSelector, (root) => {
    const kycStatus = kyc_status(root);
    const uploadDStatus = uploadD_status(root);
    const sumsubStatus = sumsub_status(root);

    if (!kycStatus && !uploadDStatus && !sumsubStatus) {
        return {
            possibleLinks: [`/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT}`],
            link: ``,
            icon: IconsType.verified_piqk,
            buttonType: UserDepositStatus.VERIFIED,
        };
    }

    if (kycStatus || uploadDStatus || sumsubStatus) {
        if (kycStatus) {
            return {
                possibleLinks: [`/${Pages.SUMSUB_UPLOAD}`],
                link: `/${Pages.MY_WALLET}/${Pages.MY_WALLET__DEPOSIT}`,
                buttonType: UserDepositStatus.KYC,
                icon: IconsType.not_verified_piqk,
            };
        }
        if (config.featuresFlags['sumsub'])
            return {
                possibleLinks: [`/${Pages.SUMSUB_UPLOAD}`],
                link: `/${Pages.SUMSUB_UPLOAD}`,
                buttonType: UserDepositStatus.KYC,
                icon: IconsType.not_verified_piqk,
            };
        if (config.featuresFlags['uploadDocuments'])
            return {
                possibleLinks: [`/${Pages.UPLOAD_DOCUMENTS}`],
                link: `/${Pages.UPLOAD_DOCUMENTS}`,
                buttonType: UserDepositStatus.KYC,
                icon: IconsType.not_verified_piqk,
            };
    }
});

export const user_getReferralCodeSelector = createSelector(userReducerSelector, (user) => user.referralCode);

export const user_getReferralCodeLoaderSelector = createSelector(
    userReducerSelector,
    (user) => user.referralCodeLoader
);

export const user_updateUserAddressSelector = createSelector(
    userReducerSelector,
    (user) => user.updateUserAddressStatus
);
