import { PropChallengeCP, PropChallengeConditions } from 'services/generatedClientFromSwagger';
import { isNullOrUndefined } from 'utils/isNullOrUndefined';

export const extractRelevantConditions = (challenge: PropChallengeCP, packageIndex: number) => {
    let conditions: PropChallengeConditions | {} = {};

    if (challenge?.conditions) {
        conditions = challenge?.conditions;
    }

    if (
        challenge.packages &&
        challenge.packages.length &&
        challenge.packages[packageIndex] &&
        challenge.packages[packageIndex]?.conditions
    ) {
        conditions = {
            leverage: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.leverage)
                ? challenge?.conditions?.leverage
                : challenge?.packages?.[packageIndex]?.conditions?.leverage,
            maxDailyLossPercent: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.maxDailyLossPercent)
                ? challenge?.conditions?.maxDailyLossPercent
                : challenge?.packages?.[packageIndex]?.conditions?.maxDailyLossPercent,
            maxOverallLossPercent: isNullOrUndefined(
                challenge?.packages?.[packageIndex]?.conditions?.maxOverallLossPercent
            )
                ? challenge?.conditions?.maxOverallLossPercent
                : challenge?.packages?.[packageIndex]?.conditions?.maxOverallLossPercent,
            minimumDayTrading: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.minimumDayTrading)
                ? challenge?.conditions?.minimumDayTrading
                : challenge?.packages?.[packageIndex]?.conditions?.minimumDayTrading,
            profitSplit: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.profitSplit?.trader)
                ? challenge?.conditions?.profitSplit
                : challenge?.packages?.[packageIndex]?.conditions?.profitSplit,
            profitTargetPercent: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.profitTargetPercent)
                ? challenge?.conditions?.profitTargetPercent
                : challenge?.packages?.[packageIndex]?.conditions?.profitTargetPercent,
            maxDailyProfitPercent: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.maxDailyProfit)
                ? challenge?.conditions?.maxDailyProfit
                : challenge?.packages?.[packageIndex]?.conditions?.maxDailyProfit,
            timeLimitDays: isNullOrUndefined(challenge?.packages?.[packageIndex]?.conditions?.timeLimitDays)
                ? challenge?.conditions?.timeLimitDays
                : challenge?.packages?.[packageIndex]?.conditions?.timeLimitDays,
            allowWeekends: challenge?.conditions?.allowWeekends
                ? challenge?.conditions?.allowWeekends
                : challenge?.packages?.[packageIndex]?.conditions?.allowWeekends,
        };
    }

    return conditions;
};
