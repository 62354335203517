import { connect } from 'react-redux';
import React, { forwardRef, useCallback } from 'react';
import {
    MyWalletPreConditionProps,
    mapDispatchToProps,
    mapStateToProps,
    preDepositFormInputsScheme,
    preDepositFormValidationsScheme,
} from './my-wallet_pre-condition.scheme';
import useStyles from './my-wallet_pre-condition.styles';
import { useTranslation } from 'react-i18next';
import FormGenerator from 'components/FormGenerator';
import { AccountInfoParsed } from 'services/generatedClientFromSwagger';
import config from 'config/common';
import { FormGeneratorHandle } from 'components/FormGenerator/form-generator.render';
import Typography from 'components/Typography';
import store from 'store/index';
import { user_UpdateUserInfo } from 'store/user/actions';
const MyWalletPreCondition = forwardRef<FormGeneratorHandle, MyWalletPreConditionProps>((props, ref) => {
    const {
        userFullAddress,
        userAddressObject,
        userDateOfBirth,
        countriesList,
        countriesListDialCodes,
        phoneNumber,
        ipData,
        // user_UpdateUserInfo,
        disabled,
        dataLoader,
        authLoader,
        callToPaymentIframe,
        firstName,
        lastName,
    } = props;

    const classes = useStyles();
    const { t, i18n } = useTranslation(['myWallet', 'prop']);
    const { language } = i18n;
    // const dispatch = useDispatch();
    const handleOnSubmit = useCallback(
        async (values: any) => {
            const {
                phoneNumber: newPhoneNumber,
                phoneCountryCode,
                custom_field_dateOfBirth,
                location,
                firstName: newFirstName,
                lastName: newLastName,
            } = values;

            const { country, country_short, city, address, addressNumber, postalCode, state } = location;

            const newObject: AccountInfoParsed = {};

            if (config.preDepositFlags['phoneNumber'] && (!phoneNumber || config.preDepositFlags['addAddressSilent'])) {
                newObject.phoneNumber = newPhoneNumber;
                newObject.phoneCountryCode = phoneCountryCode;
            }

            if (config.preDepositFlags['firstName'] && (!firstName || config.preDepositFlags['addAddressSilent'])) {
                newObject.firstName = newFirstName;
            }

            if (config.preDepositFlags['lastName'] && (!lastName || config.preDepositFlags['addAddressSilent'])) {
                newObject.lastName = newLastName;
            }

            if (config.preDepositFlags['dateOfBirth']) {
                newObject.custom_field_dateOfBirth = custom_field_dateOfBirth;
            }

            if (config.preDepositFlags['address'] || (config.preDepositFlags['addAddressSilent'] && !ipData)) {
                newObject.country = country;
                newObject.city = city;
                newObject.address1 = `${address} ${addressNumber || ''}`.trim();
                newObject.zipCode = postalCode;
                newObject.state = state;
                newObject.country_short = country_short;
            }

            if (config.preDepositFlags['addAddressSilent'] && ipData) {
                newObject.country = ipData.country;
                newObject.country_short = ipData.countryCode;
                newObject.city = ipData.city;
                newObject.zipCode = ipData.zip;
            }
            console.log('be');
            try {
                const isSuccess = await store.dispatch(user_UpdateUserInfo(newObject) as any);
                if (typeof callToPaymentIframe === 'function') {
                    callToPaymentIframe();
                }
                return isSuccess;
            } catch (error) {
                console.log('error', error);
            }
        },
        [callToPaymentIframe, phoneNumber, user_UpdateUserInfo, firstName, lastName, ipData]
    );
    return (
        <div className={classes.wrapper}>
            <Typography variant={'tLgSemibold'} className={classes.challengesTitle} color="primary">
                {t('prop:checkout.customer_details')}
            </Typography>
            {!authLoader && !dataLoader && (
                <FormGenerator
                    disabled={disabled}
                    ref={ref}
                    onSubmitClick={handleOnSubmit}
                    cancelBtnProps={{ disabled: true }}
                    validationsScheme={preDepositFormValidationsScheme(
                        t,
                        !config.preDepositFlags['addAddressSilent'] ? phoneNumber : undefined,
                        !config.preDepositFlags['addAddressSilent'] ? firstName : undefined,
                        !config.preDepositFlags['addAddressSilent'] ? lastName : undefined
                    )}
                    inputs={preDepositFormInputsScheme({
                        date: userDateOfBirth,
                        location: userFullAddress,
                        userAddressObject,
                        countriesList,
                        countriesListDialCodes,
                        dataLoader,
                        phoneNumber: !config.preDepositFlags['addAddressSilent'] ? phoneNumber : undefined,
                        lang: language,
                        ipData,
                        firstName: !config.preDepositFlags['addAddressSilent'] ? firstName : undefined,
                        lastName: !config.preDepositFlags['addAddressSilent'] ? lastName : undefined,
                        t,
                    })}
                />
            )}
        </div>
    );
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    React.memo(MyWalletPreCondition)
);
